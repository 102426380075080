<template>
	<CoverageDetails
		v-if="!store.state.loading && details"
		:participant="activeParticipant"
		:details="details"
		@relatedClick="relatedClick"
	/>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref, watch, computed } from 'vue';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import store from '@/store/index';
import router from '@/router/index';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';
// models
import Dependent from '@/models/Dependent.js';
import ExtendedHealthBenefitDetail from '@/models/coverage/ExtendedHealthBenefitDetails';
// components
import CoverageDetails from '@/components/coverage/CoverageDetails.vue';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;

watch(
	() => root.$i18n.locale,
	async () => {
		await getDetails();
	}
);

// data
const details = ref(null);
const activeParticipant = ref(null);

// computed
const shortDesc = computed(() => {
	return details.value?.shortDescription;
});

// methods
async function relatedClick(clickedService) {
	router.push({
		query: { code: clickedService.code, participant: activeParticipant.value.participantId }
	});
	await getDetails();
}
async function getDetails() {
	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');
	const locale = root.$i18n.locale;
	const exists = await ExtendedHealthBenefitDetail.hasDetails(
		activeParticipant.value.participantId,
		root.$route.query.code,
		locale
	);

	if (!exists) store.dispatch('updateLoading', true);

	details.value = await ExtendedHealthBenefitDetail.getBenefitDetails(
		email,
		activeParticipant.value.participantId,
		root.$route.query.code,
		token,
		locale
	);

	useBreadcrumbs(
		[
			{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
			{ text: t('breadcrumb.ehb', 'en'), to: { name: COVERAGE.EXTENDED_HEALTH } },
			{ text: shortDesc }
		],
		[
			{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
			{ text: t('breadcrumb.ehb', 'fr'), to: { name: COVERAGE.EXTENDED_HEALTH } },
			{ text: shortDesc }
		]
	);

	store.dispatch('updateLoading', false);
}

// lifecycle hooks
onMounted(async () => {
	activeParticipant.value = await Dependent.getDependent(
		sessionStorage.getItem('email'),
		sessionStorage.getItem('apiToken'),
		root.$i18n.locale,
		root.$route.query.participant
	);

	await getDetails();
});
</script>

<i18n lang="json">
{
	"en": {
		"breadcrumb": {
			"home": "Home",
			"myCoverage": "Plan Coverage",
			"ehb": "Extended Health Benefits"
		}
	},
	"fr": {
		"breadcrumb": {
			"home": "Accueil",
			"myCoverage": "Couverture du régime",
			"ehb": "Soins de santé complémentaires"
		}
	}
}
</i18n>
